import React from 'react'
import { Link } from 'react-router-dom'


export default function Policies() {
  return (
    <div>
      
       <div className='w-full text-center'>
      <h1 className='text-2xl font-medium m-6'>Policies</h1>
      <div className='flex flex-col justify-center items-center'>
            <p className=' text-lg font-medium border-slate-500 p-8 bg-slate-200 rounded-lg border-2'>Here at OopsScoop.com, our policy is to provide the best service we possibly can!</p>
            <p className='font-thin mt-8'>By requesting Oops Scoop Service, I agree to the following:</p>
            <ul className='pl-12 pr-12 mt-6 mb-8'>
            <li className='text-lg font-medium p-2'>First visit Inspection</li>
                  <span className='text-md font-thin text-sm'>To guarantee proper pick-up plan placement, <br /> we evaluate upon inspection of our first visit. <br />
                   We practice equality with our clients and want <br /> to ensure our clients are billed properly! <br />
                   Therefore the final weekly price will be determined on the first inspection, <br />
                   meeting the pricing plan determined by OopsScoop.com payment standard.<br />
                   The first visit will be an Initial pickup charges. This charge will be upfront and on the invoice provided by Oops Scoop. <br />
                   If you, the client, requires a change of plan upon the first visit, you will be notified and billed within 72 hours. <br />
                   Therefore the first bill is subject to change. <br />
                    After the first inspection, 
                   the price stated on the invoice is not subject to change after the first inspection if payments are made on time and in full
                   </span>
                   <br /><br />
                   <li className='text-lg font-medium p-2'>Scheduling Policy</li>
                  <span className='text-md font-thin text-sm'>
                  At OopsScoop.com, we are dedicated to providing timely and reliable poop scoop services to our clients.<br />
                  We schedule a weekly pickup day that works for both the client and our team,<br />
                   and we guarantee that we will pick up before dark on that day.<br />
                   If there are any issues that may affect our ability <br />
                    to complete the scheduled pickup, such as unsafe road conditions, <br />
                   inclement weather, or other unexpected circumstances, <br />
                    we will notify the client via email and/or text <br />
                   to provide a courtesy notice of any necessary rescheduling. <br />
                    We will work with our clients to find a mutually agreed-upon <br />
                   alternative pickup day that works for both parties.<br />
                   Our goal is to ensure that our clients are satisfied with our services and<br />
                    that their properties remain clean and free of dog waste.
                         </span>
                   <br /><br />
                   <li className='text-lg font-medium p-2'>Payment Policy</li>
                  <span className='text-md font-thin text-sm'>All services are paid in advanced and scheduled per client request. <br /> Failure to complete payment in any way will result in 
                  a pause of service.
                  <br />
                  Invoices are sent by mail or email, and are due on the 15th of every month. 
                  <br /><br />
                  For new OopsScoop members,<br /> 
                   Payment is required in advance for pick-up days up until <br /> the 15th day of the current month.<br />
                   If the member begins service after the 15th day of the current month,<br />
                    payment will be required in advance for pick-up days up until <br /> the 15th day of the following month. <br />
                   </span>
                   <br />
                  <li className='text-lg font-medium p-2'>Cancelation Policy <br />
                  <span className='text-md font-thin text-sm'>You may cancel at any time! <br /> The service will expire on the following billing cycle, <br /> and you will no longer be billed.</span>
                  </li>
                  <br />

                  <li className='text-lg font-medium p-2'>Communication Policy <br />   </li>
                  <span className='text-md font-thin text-sm'>
                  At OopsScoop.com, we are committed to clear and timely communication with our clients. <br />
                  If you have any concerns or complaints about our services,  <br />
                   we encourage you to contact us as soon as possible<br />
                   so that we can address them promptly.<br />
                    You can reach us by phone at  <br />
                    Polly's number 509-868-2745 or  <br />
                     Eric's number 509-868-2692.<br />
                     Alternatively, you can email us directly at: <br /> oopsscoop@usa.com <br /> for any issues that may arise.<br />
                     <br />
We will make every effort to respond to your concerns or <br /> complaints within 24 hours of receipt of your message. <br />
We take all feedback seriously, and we are dedicated to  <br /> ensuring that our clients are completely satisfied with our services. <br />
If there are any changes to our schedule or services, <br /> we will notify our clients as soon as possible to minimize any inconvenience.<br />
 Our goal is to maintain open and honest communication with our clients at all times and<br />
  to provide top-notch poop scoop services that exceed their expectations.
  </span>
               
                  <br /><br />
            
 <li className='text-lg font-medium p-2'>Confidentiality Policy </li>
                  <span className='text-md font-thin text-sm'>
                  Collection of Information: We will only collect information that is necessary for the purpose of providing our poop scoop service. <br />
                  We will obtain consent from clients before collecting any personal information and will only use it for the purpose for which it was collected.
                  <br />
Use of Information: We will only use client information for the purpose of providing our poop scoop service. <br />
We will not share or disclose any personal or sensitive information to any third party without the client's explicit consent, unless required by law.
<br />
Storage of Information: We will take reasonable measures to ensure the security of all client information. <br />
We will store all personal and sensitive information in a secure location and protect it from unauthorized access, use, or disclosure.
<br />
Access to Information: We will only allow authorized personnel to access client information. <br />
Our team members will only access client information that is necessary for the purpose of providing our poop scoop service.
<br />
Retention of Information: We will retain client information only for as long as necessary to provide our poop scoop service, or as required by law. <br />
Once the information is no longer necessary, we will securely destroy or delete it.
<br />
Confidentiality Agreement: All of our team members will be required to sign a confidentiality agreement, <br />
which outlines their obligations to protect the privacy and confidentiality of client information.
<br />
As part of our commitment to providing high-quality poop scoop services, <br />
we take before and after photos of every yard we service. <br />
By engaging our services, you agree to this policy and understand the benefits it provides.
<br />
The before photos enable us to assess the level of cleanup needed and identify any potential hazards or obstacles in the yard.<br />
 This helps us to plan and execute the job more efficiently and safely. The after photos document the quality of our work and<br />
  ensure that every inch of the yard has been thoroughly cleaned, leaving no debris behind.
  <br />
Please note that all photos are kept confidential and used only for internal purposes.<br />
 We respect your privacy and will never share any information without your permission.
 <br />
If you have any questions or concerns about our photo policy or our poop scoop services, <br />
please feel free to contact us at any time. We are always happy to address any issues and ensure your complete satisfaction.
                  
 </span>
 <br /><br />


 <li className='text-lg font-medium p-2'>No Access Policy</li>
                  <span className='text-md font-thin text-sm'>f our scoops are unable to clean the yard due to a unaccessible yard, <br /> (e.g. locked gate, or physically blocked) <br /> you will be billed for a standard pick-up and <br /> we will contact you directly to make sure we may pick-up <br /> on the following regularly schedule pick-up.</span>
                  <br /><br />
                  <li className='text-lg font-medium p-2'>Dangerous Animal Policy</li>
                  <span className='text-md font-thin text-sm'>If our scoopers are unable to clean the yard due to a dangerous animal, <br /> you will be billed for a standard pick-up and <br /> we will contact you directly to make sure we may pick-up <br /> on the following regularly schedule pick-up.</span>
      
            
         

            <br /><br />

            <li className='text-lg font-medium p-2'>Right to refuse service</li>
                  <span className='text-md font-thin text-sm'>
                  At our poop scoop service, we reserve the right to refuse service to any client for any reason. <br />
                  We value respectful communication and professional conduct with our clients,<br />
                   and any behavior that is deemed inappropriate or disrespectful towards our team members will not be tolerated.
                  <br />
In addition, we prioritize the safety and well-being of our team members, <br />
 and we may refuse service if a client's property poses a safety risk or <br />
  if our team members feel uncomfortable or threatened while performing their duties.
<br />
We strive to provide high-quality and reliable service to all of our clients, <br /> 
and we will do our best to communicate any concerns or <br />
 issues that may arise in a respectful and professional manner. <br />
 However, if a client's behavior or property poses a significant risk to our team members <br />
  or interferes with our ability to provide quality service, we reserve the right to refuse service.
 </span>
<br /><br />
 <li className='text-lg font-medium p-2'>Liability Waiver Policy </li>
                  <span className='text-md font-thin text-sm'>
                  I, the undersigned client, hereby acknowledge and agree that I have voluntarily chosen to use the services of OopsScoop.com <br />
                   for the purpose of poop scoop service at my property. <br />
                    In consideration for such services, I hereby agree to the terms of this Liability Waiver.<br />
                    hereby acknowledge that I have hired OopsScoop.com to provide pet waste removal services for my property.<br />
                     As a condition of this agreement, I agree to the following terms:<br />
                     <br />
Assumption of Risk: I acknowledge that pet waste removal services may involve certain risks and hazards, <br />
including, but not limited to, slips, falls, and exposure to pet waste. <br />
I understand and accept these risks and voluntarily assume all risks associated <br />
with the use of OopsScoop.com's services.<br />
<br />
Release of Liability: I hereby release, discharge, and hold harmless OopsScoop.com, <br />
its officers, employees, and agents from any and all claims, demands, causes of action, damages,<br />
 or expenses arising out of or in connection with the use of OopsScoop.com's services. <br />
 This release includes any claims, damages, or expenses resulting <br />
 from personal injury, property damage, or wrongful death.<br />
 <br />
Indemnification: I agree to indemnify and hold harmless OopsScoop.com, <br />
its officers, employees, and agents from any and all claims, demands, causes of action,<br />
 damages, or expenses arising out of or in connection with my use of OopsScoop.com's services.<br />
 <br />
Acknowledgment of Understanding: I have read and understand this Liability Waiver, <br />
and I am voluntarily signing it as evidence of my agreement to its terms.<br />
<br />
Governing Law: This Liability Waiver shall be governed by and construed in accordance<br />
 with the laws of the state in which OopsScoop.com provides services.<br />
 <br />
I acknowledge that I have carefully read this Liability Waiver and fully understand its contents.<br />
 I am aware that this is a release of liability and a contract between myself and OopsScoop.com,<br />
  and I sign it of my own free will.<br />
<br />
By placing payment for services with the Company, I acknowledge and agree that I have read, <br />
 understood, and agreed to the terms of this Liability Waiver, as well as any other <br />
  policies or agreements posted on the Company's website.   
  <br />
  <br />
  <br />
  <br />

   </span>
   <br /><br />
            
            </ul>

            
<p className='mb-8 font-medium'>Have a question? <br /> Feel free to <span> 
  <Link className="mr-3 underline focus:text-blue-400 text-blue-700 hover:text-blue-300 font-medium text-lg"
   to="/contact">Contact us today </Link> </span> </p>



</div>
</div>
    </div>
  )
}
